import * as React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Helmet } from 'react-helmet';
import ReCAPTCHA from 'react-google-recaptcha';
import Layout from '../components/Layout';
import Gallery from '../components/Gallery';

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

// markup
const ContactPage = () => {
  const [state, setState] = React.useState({});
  const recaptchaRef = React.createRef();

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    // const recaptchaValue = recaptchaRef.current.getValue();
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        // 'g-recaptcha-response': recaptchaValue,
        ...state,
      }),
    })
      .then(() => {
        window.location.href = form.getAttribute('action');
      })
      .catch((error) => console.error(error));
  };

  const data = useStaticQuery(graphql`
  query {
    allFile(filter: {sourceInstanceName: {eq: "gallery"}, dir: {regex: "/.*\/kaminad$/"}}) {
    nodes {
      dir
      childImageSharp {
        gatsbyImageData(width: 500, quality: 80)
        id
        original {
          src
        }
      }
    }
  }
  }
`);

  const imgs = data
    && data.allFile
    && data.allFile.nodes
    && data.allFile.nodes.map((n) => ({ ...n.childImageSharp }));

  return (
    <Layout pageTitle="Võta ühendust">
      <section className="container section">

        <form
          onSubmit={handleSubmit}
          name="ahjud_pro"
          method="post"
          action="/saadetud/"
          netlify-honeypot="bot-field"
          data-netlify="true"
          // data-netlify-recaptcha="true"
        >
          <input type="hidden" name="form-name" value="ahjud_pro" />
          <input type="hidden" name="bot-field" />

          <div className="field">
            <label className="label">Nimi</label>
            <div className="control">
              <input className="input" type="text" placeholder="" name="nimi" onChange={handleChange} />
            </div>
          </div>
          <div className="field">
            <label className="label">Email</label>
            <div className="control has-icons-left has-icons-right">
              <input className="input" type="email" name="email" onChange={handleChange} />
              <span className="icon is-small is-left">
                <i className="fas fa-envelope" />
              </span>
            </div>
          </div>
          <div className="field">
            <label className="label">Telefon</label>
            <div className="control">
              <input className="input" type="text" placeholder="" name="telefon" onChange={handleChange} />
            </div>
          </div>
          <div className="field">
            <label className="label">Sõnum</label>
            <div className="control">
              <textarea className="textarea" name="tekst" onChange={handleChange} />
            </div>
          </div>
          {/* <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LeEprcaAAAAAKAhoeE4pIlKXDJhcaITwVaEfRxw"
          /> */}
          ,
          <input name="submit" type="submit" value="Submit" />
        </form>

      </section>
    </Layout>
  );
};

export default ContactPage;
